ion-split-pane {
    --side-width: 260px;
    --side-max-width: 260px;
  
    --border: 1px dashed #b3baff;
  }


  table {
    border: 1px dashed #b3baff;
    width: 180px;
    height: 90px;
}
 
th {
    border-bottom: 1px dashed #b3baff;
}
 
td {
    text-align: center;
}

.App {
    width: 65%;
    height: 40vh; 
    display: flex; 
    justify-content: center;
    align-items: center;
    /* overflow-x:auto; */
}

.tableTable {
    border: 2px solid forestgreen;
    width: 900px;
    height: 200px;
}
 
.thTable {
    border-bottom: 1px solid black;
}
 
.tdTable {
    text-align: center;
}
.tdTableMonto {
    text-align: right;
}

.default-alert{
   --max-width: unset !important;
   --max-height	: unset !important;
   --width: 80% !important;
   --height:  80% !important;
   --border-radius:5px!important;
}

ion-spinner {
  width: 70px;
  height: 70px;
}

ion-toast.custom-toast {
    --background: #f4f4fa;
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --color: #4b4a50;
  }
  
  ion-toast.custom-toast::part(message) {
    font-style: italic;
  }
  
  ion-toast.custom-toast::part(button) {
    border-left: 1px solid #d2d2d2;
    color: #030207;
    font-size: 15px;
  }




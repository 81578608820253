@font-face {
  font-family: 'GillSans';
  src: local('GillSans'), url('./fonts/Gill\ Sans.woff2') format('woff2');
  font-weight: normal;
}

/* variables */

:root {
  --ion-color-base: #040404;
  --ion-background-color: #F8f8f8;

  --ion-color-primary: #001E60;
  --ion-color-primary-rgb: 0, 48, 96;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #002a54;
  --ion-color-primary-tint: #1a4570;

  --ion-color-secondary: #742870;
  --ion-color-secondary-rgb: 116, 40, 112;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #662363;
  --ion-color-secondary-tint: #823e7e;

  --ion-color-tertiary: #BF406C;
  --ion-color-tertiary-rgb: 191, 64, 108;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #a8385f;
  --ion-color-tertiary-tint: #c5537b;

  --ion-color-success: #00E2B1;
  --ion-color-success-rgb: 0, 226, 177;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #00c79c;
  --ion-color-success-tint: #1ae5b9;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #94001F;
  --ion-color-danger-rgb: 148, 0, 31;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #82001b;
  --ion-color-danger-tint: #9f1a35;

  --ion-color-medium: #474555;
  --ion-color-medium-rgb: 71, 69, 85;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #3e3d4b;
  --ion-color-medium-tint: #595866;

  --ion-color-light: #ABA9BB;
  --ion-color-light-rgb: 171, 169, 187;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #9695a5;
  --ion-color-light-tint: #b3b2c2;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* Dark mode variables go here */

    /* --ion-color-base: #F8f8f8;
    --ion-background-color: #040404; */
  }
}

.ion-color-white {
  --ion-color-base: #ffffff;
  --ion-color-base-rgb: 255, 255, 255;
  --ion-color-contrast: #000000;
  --ion-color-contrast-rgb: 0, 0, 0;
  --ion-color-shade: #e0e000;
  --ion-color-tint: #ffffff;
}

ion-modal {
  --width: 52rem;
  --border-radius: 10px;
}

ion-button {
  text-transform: none;
}

.mainTitleSize {
  font-size: 50px;
}

/* ion-menu::part(backdrop) {
  background-color: rgba(255, 0, 255, 0.5);
}

ion-menu::part(container) {
  border-radius: 0 20px 20px 0;

  box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18);
} */

/* default general values */

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  font-family: 'GillSans', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  font-size: 1em;

  background-color: var(--color-background);
  color: var(--color-text);

  overflow-x: hidden;
}

.bold-cell {
  font-weight: bold;
  background-color:#d8ecff;
}

.vh100 {
  height: 100vh;
}

.bold {
  font-weight: bold;
}

.w100 {
  width: 100%;
}

/* Welcome */

#welcome>* {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

#welcome {
  height: 100%;
  display: grid;
  place-items: center;
}

#welcome>div>.logo {
  font-size: 3em;
  line-height: 1em;
  color: var(--color-a);
}


.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-5-11 17:10:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}





/*
input[type="checkbox"] {
  /* Add if not using autoprefixer * /
  -webkit-appearance: none;
  /* Remove most all native input styles * /
  appearance: none;
  /* For iOS < 15 * /
background-color: var(--ion-color-primary);
/* Not removed via appearance * /
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 50ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--ion-background-color);
  /* Windows High Contrast Mode * /
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--ion-color-step-500);

  color: var(--ion-color-step-400);
  cursor: not-allowed;
}
*/